import { Injectable } from '@angular/core';
import { APPS } from 'libs/utils/constants';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private _appName!: APPS;

  set appName(value: APPS) {
    this._appName = value;
  }

  get appName() {
    return this._appName;
  }

  isOfficeConnect() {
    return this._appName === APPS.CONNECT_OFFICE;
  }
}
