import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'libs/environment/environment';
import { Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AngularFireAuth, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.idTokenResult.pipe(
      take(1),
      switchMap((idTokenResult) => {
        // Confirm the user is an Admin.
        if (idTokenResult && idTokenResult.claims.internalAccess) {
          return of(true);
        } else {
          return fromPromise(this.router.navigateByUrl('signout')).pipe(
            map(() => false)
          );
        }
      }),
      catchError((err) => {
        if (!environment.production) {
          console.error('Error at Auth Guard => ', err);
        }
        return fromPromise(this.router.navigateByUrl('signout')).pipe(
          map(() => false)
        );
      })
    );
  }
}
